@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
*, *::before, *::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.text{
    margin: auto;
    width: 50%;
    border: 2px solid #222;
    padding: 5rem 4rem;
    font-family: 'Roboto', sans-serif;
    margin-top: 5rem;
    margin-bottom: 5rem;
    text-align: center;
    border-radius: 10px;
    text-align: start;
    background-color: #ffff;
    font-size: 1rem;
    border-bottom: 4px solid #222;
    box-shadow: 0 4px 4px 0 #222, 0 4px 4px 0 #222;
    color: #323232;
    
}

.text-item{
 
    padding:  0.5rem 0;
}


@media only screen and (max-width: 600px){
    .text{
      font-size: 1.5vh;
      padding: 0;
      padding-left: 0.2rem;
      
    }
  }
.navbar{
    background-color: #222;
    margin: auto;
    width: 100%;
    color: #fff;
    font-weight: bolder;  
    font-size: 1.5rem;
}

.navbar-text{
   font-family: 'Roboto', sans-serif;
   padding-top: 2rem;
   padding-bottom: 2rem;
   text-align: center;
}

.logo-img{
    width: 100%;
    margin-top: -10rem;
    
    
}

@media only screen and (max-width: 600px){
    .logo-img{
        width: 100%;
       margin-top: -2rem;
        
      
    }
  }
.footer{
    
    display: flex;
    justify-content: space-around;
    background-color: #222;
    color: white;
    text-align: center;
    margin-top: 5rem; 
    padding: 1rem 0;
    font-size: 1.3rem;
    
}

.copyright{
    display: flex;
    justify-content: center;
    

}




.contact-header{
    margin: auto;
    width: 50%;
    margin-bottom: 2rem;
    text-align: center;
   
    color: #423F3E;
    
    
}

.form-full{
    
    
    background-color: #222;
    width: 100%;
    height: 32rem;
    padding-top: 1rem;
   
    
}

.form-main{
    border-radius: 10px;
    box-shadow: 0 4px 4px 0 rgba(237, 241, 236, 0.493), 0 4px 4px 0 rgba(237, 241, 236, 0.493);
    background-color: #eee;
   padding: 2rem;
    box-sizing: border-box;
     margin: auto;
    margin-top: 1rem;
    width: 50%;
}
  
 

  
  .form-field {
    
    display: flex;
    margin: 0 0 1rem 0;
  }
  input,textarea {
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    justify-content: space-between;
    font-size: 1.1rem;
    border: 2px solid #aaa;
    border-radius: 2px;
  }

 

  .contact-btn:hover {
   background-color:#222 ;
   color: #fff;
  }


  .contact-btn:active {
    
    box-shadow: 0 1px #2222;
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
  
.confident{
    display: flex;
    padding: 0 0.2rem ;
    justify-content: center;
    font-weight: bold;
    
    
}

@media only screen and (max-width: 600px){
  .confident{
    font-size: 1.5vh;
    
  }
}
