.contact-header{
    margin: auto;
    width: 50%;
    margin-bottom: 2rem;
    text-align: center;
   
    color: #423F3E;
    
    
}

.form-full{
    
    
    background-color: #222;
    width: 100%;
    height: 32rem;
    padding-top: 1rem;
   
    
}

.form-main{
    border-radius: 10px;
    box-shadow: 0 4px 4px 0 rgba(237, 241, 236, 0.493), 0 4px 4px 0 rgba(237, 241, 236, 0.493);
    background-color: #eee;
   padding: 2rem;
    box-sizing: border-box;
     margin: auto;
    margin-top: 1rem;
    width: 50%;
}
  
 

  
  .form-field {
    
    display: flex;
    margin: 0 0 1rem 0;
  }
  input,textarea {
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    justify-content: space-between;
    font-size: 1.1rem;
    border: 2px solid #aaa;
    border-radius: 2px;
  }

 

  .contact-btn:hover {
   background-color:#222 ;
   color: #fff;
  }


  .contact-btn:active {
    
    box-shadow: 0 1px #2222;
    transform: translateY(5px);
  }
  
.confident{
    display: flex;
    padding: 0 0.2rem ;
    justify-content: center;
    font-weight: bold;
    
    
}

@media only screen and (max-width: 600px){
  .confident{
    font-size: 1.5vh;
    
  }
}