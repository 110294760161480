.footer{
    
    display: flex;
    justify-content: space-around;
    background-color: #222;
    color: white;
    text-align: center;
    margin-top: 5rem; 
    padding: 1rem 0;
    font-size: 1.3rem;
    
}

.copyright{
    display: flex;
    justify-content: center;
    

}



