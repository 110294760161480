@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.navbar{
    background-color: #222;
    margin: auto;
    width: 100%;
    color: #fff;
    font-weight: bolder;  
    font-size: 1.5rem;
}

.navbar-text{
   font-family: 'Roboto', sans-serif;
   padding-top: 2rem;
   padding-bottom: 2rem;
   text-align: center;
}

.logo-img{
    width: 100%;
    margin-top: -10rem;
    
    
}

@media only screen and (max-width: 600px){
    .logo-img{
        width: 100%;
       margin-top: -2rem;
        
      
    }
  }